/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

* {
  touch-action: pan-x pan-y !important;
  -webkit-text-size-adjust: 100% !important;
}



@keyframes example {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.invalid-tooltip {
  margin-top: -8px;
}

img {
  border: 0;
  outline: none;
  max-width: 100%;
}

/* scroll styles */

::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #acacac;
}

body.en {
  #lim_mini {
    left: 2vw !important;
    right: 0 !important;
    bottom: 2vh !important;
  }
}

body.ar {
  #lim_mini {
    right: 2vw !important;
    left: 0 !important;
    bottom: 2vh !important;
  }

}
